import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
// import { TextField, Checkbox, FormControlLabel} from "@material-ui/core"
import axios from "axios"
import { toast } from "react-toastify";
import { API_ROOT } from "gatsby-env-variables"

class requestPage extends React.Component {
  state = {
    name: "",
    position: "",
    company: "",
    email: "",
    telephone: "",
    newsletter: false,
    errors: {
      name: '',
      position: '',
      company: '',
      email: '',
      telephone: ''
    }
  }
  componentDidMount() {
    if(window.$crisp && window.$crisp !== undefined){
      window.$crisp.push(['do', 'chat:hide']);
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    let value = null;
    if(name === 'newsletter'){
      value = target.checked;
    } else {
      value = target.value;
    }
    this.setState({
      [name]: value
    })
  }
  validateForm = () => {
    let validate = false;
    let errors = this.state.errors;
    if(!this.state.name){
      errors.name = 'Required Filed';
      validate = false;
    } else {
      errors.name = '';
      validate = true;
    }
    if(!this.state.company){
      errors.company = 'Required Filed';
      validate = false;
    } else {
      errors.company = '';
      validate = true;
    }
    if(!this.state.email){
      errors.email = 'Required Filed';
      validate = false;
    } else {
      errors.email = '';
      validate = true;
    }
    this.setState({errors : errors})
    return validate
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()){
      var requestObj = this.state;
      if(requestObj.newsletter === true){
        requestObj.newsletter = 1;
      } else {
        requestObj.newsletter = 0;
      }
      delete requestObj.errors
      axios.post(API_ROOT+`/api/v1/requests`,
        requestObj
      )
        .then((response) => {
          toast.success('Thank you for your request. We will get back to you as soon as possible.')
        })
        .catch(function (error) {
          if(error.response && error.response.status && error.response.status === 422){
            if(error.response.data && error.response.data.errors){
              for(var key in error.response.data.errors){
                var errorMsg  = error.response.data.errors[key];
                toast.error(errorMsg[0])
              }
            }
          } else{
            toast.error('an error occured.')
          }
        });
    }
  }
  render() {
    return (
      <Layout pageInfo={{ pageName: "Sign up" }}>
        <SEO title="Request" />
        <Container fluid className={'inline-pages'}>
          <Row className={'text-center'}>
            <Col className={'inline-pages-title'} md={{span:10, offset:1}}>
              <p>Request</p>
            </Col>
            <Col className={'inline-pages-border'} md={{span:10, offset:1}}></Col>
            <Col md={{span:10, offset:1}} className={'top-z-index'}>
              <p className={'bread-crumb'}>
                home  /  Request
              </p>
            </Col>
          </Row>
          <Row>
            <Col className={'inline-pages-content-box'} md={{span:10, offset:1}}>
              <p className={'request-title'}>Request login to Risklick platform</p>
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col className={'mb20'} md={{span:5, offset:1}}>
                    {/* <TextField
                      label="Name"
                      className={'col-md-12'}
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                      error={this.state.errors.name !== ''}
                      helperText={this.state.errors.name}
                      required
                    /> */}
                  </Col>
                  <Col className={'mb20'} md={{span:5}}>
                    {/* <TextField
                      label="Position"
                      className={'col-md-12'}
                      name="position"
                      value={this.state.position}
                      onChange={this.handleInputChange}
                      error={this.state.errors.position !== ''}
                      helperText={this.state.errors.position}
                    /> */}
                  </Col>
                  <Col className={'mb20'} md={{span:5, offset:1}}>
                    {/* <TextField
                      label="Company"
                      className={'col-md-12'}
                      name="company"
                      value={this.state.company}
                      onChange={this.handleInputChange}
                      error={this.state.errors.company !== ''}
                      helperText={this.state.errors.company}
                      required
                    /> */}
                  </Col>
                  <Col className={'mb20'} md={{span:5}}>
                    {/* <TextField
                      label="Email"
                      className={'col-md-12'}
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      error={this.state.errors.email !== ''}
                      helperText={this.state.errors.email}
                      required
                    /> */}
                  </Col>
                  <Col className={'mb20'} md={{span:5, offset:1}}>
                    {/* <TextField
                      label="Telephone"
                      className={'col-md-12'}
                      name="telephone"
                      value={this.state.telephone}
                      onChange={this.handleInputChange}
                      error={this.state.errors.telephone !== ''}
                      helperText={this.state.errors.telephone}
                    /> */}
                  </Col>
                  <Col className={'mb20 mt20'} md={{span:10, offset:1}}>
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          fontSize="small"
                          name="newsletter"
                          value={this.state.newsletter}
                          onChange={this.handleInputChange}
                        />
                      }
                      label="Yes, I’m interested in receiving newsletter"
                    /> */}
                  </Col>
                  <Col className={'mb20 text-center'} md={{span:12}}>
                    <button type='submit' className={'btn submit-btn'}>
                      Submit request
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default requestPage
